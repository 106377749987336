import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { AppState } from '../../dashboard/_state/state';
import { DashboardService } from '../../services/dashboard.service';
import { selectUserId } from '../../state/user/selectors';
import { selectWebsiteList } from '../../state/website-list/selectors';

@Injectable({ providedIn: 'root' })
export class WebsiteGuardService {
  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private store: Store<AppState>,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(selectUserId).pipe(
      filter(Boolean),
      switchMap(() =>
        this.store.select(selectWebsiteList).pipe(
          mergeMap((websites) => {
            if (!websites.length) {
              return this.dashboardService
                .getWebsites()
                .pipe(map((websites) => (websites.length ? true : this.router.parseUrl('/import'))));
            } else {
              return of(true);
            }
          }),
        ),
      ),
    );
  }
}
